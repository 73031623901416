.faqSectionContainer {
  margin: 30px 0;
}

.faqContainer {
  width: 75%;
  margin: 0 12.5%;
  margin-bottom: 30px;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 10px 20px !important;
  color: #333 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 15px !important;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  font-size: 13.5px !important;
}

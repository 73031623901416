:root {
  --purple: #6838be;
  --red: #e56c68;
  --orange: #f4ad6f;
  --pink: #df5187;
  --font-family: "Poppins", sans-serif;
  --font-size1: 15px;
}

/************************  TEXT  ************************/

.mainHeaders {
  font-size: 60px;
  font-family: var(--font-family);
  font-weight: 300;
  margin-bottom: 20px;
}

.mainSubText {
  float: left;
  margin-bottom: 30px;
  font-size: 12px;
}

/************************  Button  ************************/

.mainBtn {
  /*  #6738be45*/
  background-color: var(--purple);
  border-radius: 8px;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  /* display: inherit; */
  margin: 0;
  min-height: 40px;
  min-width: 150px;
  padding: 8px;
  /* position: relative; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  /* transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1); */
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
}

.reject-button {
  background-color: var(--red);
  border-radius: 8px;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  /* display: inherit; */
  margin: 0;
  min-height: 40px;
  min-width: 150px;
  padding: 8px;
  /* position: relative; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  /* transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1); */
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
}

.mainPaper {
  width: 80%;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/************************  INPUT  ************************/

.mainInput {
  height: 40px;
  width: 100%;
  border-radius: 45px;
  opacity: 0.5;
  border: 2px solid var(--purple);
  margin-bottom: 15px;
  padding-left: 15px;
}

.MuiInputBase-root.mainInput {
  height: 40px;
  width: 100%;
  border-radius: 45px;
  opacity: 0.5;
  border: 2px solid var(--purple);
  margin-bottom: 15px;
  padding-left: 15px;
}

.mainInput[type="text"] {
  font-family: var(--font-family);
  font-size: 50px;
  background-color: red;
}

.add-new-folder-btn {
  color: var(--purple);
  font-size: 15px;
  font-weight: 600;
  display: flex;

  cursor: pointer;
  margin: 10px 0 15px 0;
}

.add-new-folder-btn:hover {
  color: #0a4e96;
}

.mainInput:invalid {
  background-color: white;
  border: 2px solid var(--purple);
}

.mainInput:valid {
  background-color: white;
  border: 2px solid var(--purple);
}

/**********************  Form *****************************/

.formLogo {
  height: 60px;
  position: absolute;
  top: 10px;
  left: 10px;
}

/*********************** EXTENSION  SPECIFIC   ****************************/

.backgroundStyle1 {
  background-image: linear-gradient(
    -45deg,
    #5500f3 0%,
    var(--purple) 35%,
    var(--orange) 100%
  );
  width: 100%;
  height: 400px;
}

.backgroundPopupStyle {
  /* background-image: linear-gradient(
    -45deg,
    rgb(85, 0, 243, 0.5) 0%,
    rgb(104, 56, 190, 0.5) 35%,
    rgb(244, 173, 111, 0.5) 100%
  ); */
  /* width: 100%;
  height: 70vh; */
}

/*****************  CODE  *****************/
.image-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: auto !important;
  margin-bottom: 15px !important;
  max-height: 300px !important;
  padding: 10px !important;
  border: 5px solid var(--purple) !important;
  border-radius: 5px !important;
  max-width: 80% !important;
  margin: auto !important;
}

#selected-image {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.crosshairs {
  position: absolute !important;
  width: 100% !important;
  z-index: 2147483645 !important;
}

.crosshairs.hidden {
  display: none !important;
}

.crosshairs::before,
.crosshairs::after {
  content: "" !important;
  position: absolute !important;
}

.crosshairs::before {
  height: 24px !important;
  width: 2px !important;
  background: #000 !important;
  top: -11px !important;
}

.crosshairs::after {
  width: 24px !important;
  height: 2px !important;
  background: #000 !important;
  left: -11px !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 100000000000000 !important;
}

.overlay.highlighting {
  background: none !important;
  border-color: rgba(0, 0, 0, 0.5) !important;
  border-style: solid !important;
}

/******** CONTROLS BUTTONS ******/
.controls-container {
  width: 110px !important;
  height: 50px !important;
  border-radius: 100px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  position: fixed !important;
  /* top: 20px;
  right: 20px; */
  display: flex !important;
  justify-content: space-between !important;
  padding: 5px !important;
  z-index: 1000000 !important;
  background-color: white !important;
}

.controls-container-none {
  display: none !important;
}

/* .copy-btn {
  height: 25px !important;
  width: 60px !important;
  background-color: black !important;
  color: white !important;
  font-weight: 700 !important;
  border: 1px solid black !important;
  border-radius: 20px !important;
  cursor: pointer !important;
} */

/* .code-container-header {
  width: 100% !important;
  height: 50px !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 0 !important;
  align-items: baseline !important;
  padding: 0 10px !important;
} */

.code-container {
  width: 45% !important;
  margin: 0 auto !important;
}

.MuiSvgIcon-root.controls-arrow-up {
  transform: rotate(0deg) !important;
}

.MuiSvgIcon-root.controls-arrow-down {
  transform: rotate(180deg) !important;
}

.controls-buttons-container:focus {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.controls-buttons-container {
  /* margin: 0 auto; */
  padding: 8px 8px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  border-radius: 50% !important;
}

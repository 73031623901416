.browserinherited-styles-for-exported-element {
  color: #333;
  font-family: "Poppins", sans-serif;
  font-size: 100%;
}

.browserbutton {
  background-color: #fff;
  /* border: 1px solid #eee; */
  border: 1px solid #00777b;
  border-radius: 50px;
  box-shadow: rgba(0, 12, 32, 0.04) 0 0 1px 0, rgba(10, 31, 68, 0.1) 0 3px 4px 0;
  color: #555;
  /* display: inline-block; */
  display: flex; /* Changed to flex */
  align-items: center; /* Centers items vertically */
  justify-content: center;
  height: 50px;
  line-height: 50px;
  margin-top: 0.7em;
  padding: 0 25px;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  transition-behavior: normal;

  /* will-change: transform; */
}

.browserbutton-principal,
.browser-button {
  background-color: white;
  border-style: none;
  color: #00777b;
  margin-left: 0;
}

.browserbutton-principal * {
  pointer-events: none;
}

.browserbutton:hover {
  transform: scale(1.05);
}

.browserbuttons-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.browserbuttons-wrapper div {
  flex: 1;
}

.first-browser-button {
  font-size: 1em;
}

.first-browser-button del {
  display: none;
}

@media screen and (min-width: 768px) {
  .first-browser-button {
    font-size: initial;
  }

  .browserbuttons-wrapper {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .first-browser-button del {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .browserbuttons-wrapper {
    display: none;
  }
}

.browser-text {
  font-size: 0.9em;
}

.browserbutton img {
  height: 25px;
  margin-right: 10px;
}

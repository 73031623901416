/* .tryinherited-styles-for-exported-element {
  color: #333;
  font-family: "Poppins", sans-serif;
  font-size: 100%;
}

.trybutton {
  background-color: #fff;
  border: 1px solid #00777b;
  border-radius: 50px;
  box-shadow: rgba(0, 12, 32, 0.04) 0 0 1px 0, rgba(10, 31, 68, 0.1) 0 3px 4px 0;
  color: #555;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin-top: 0.7em;
  padding: 0 25px;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  transition-behavior: normal;
}

.trybutton-principal,
.try-button {
  background-color: white;
  border-style: none;
  color: #00777b;
  margin-left: 0;
}

.trybutton-principal * {
  pointer-events: none;
}

.trybutton:hover {
  transform: scale(1.05);
}

.trybuttons-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.trybuttons-wrapper div {
  flex: 1;
}

.first-try-button {
  font-size: 1em;
}

.first-try-button del {
  display: none;
}

@media screen and (min-width: 768px) {
  .first-try-button {
    font-size: initial;
  }

  .trybuttons-wrapper {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .first-try-button del {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .trybuttons-wrapper {
    display: none;
  }
}

.text-try {
  font-size: 1.2em;
} */

.tryinherited-styles-for-exported-element {
  color: #333;
  font-family: CerebriSans-Regular, -apple-system, BlinkMacSystemFont, Roboto,
    sans-serif;
  font-size: 100%;
}

.trybutton {
  background-color: #fff;
  border: 1px solid #00777b;
  border-radius: 50px;
  box-shadow: rgba(0, 12, 32, 0.04) 0 0 1px 0, rgba(10, 31, 68, 0.1) 0 3px 4px 0;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  line-height: 50px;
  margin-top: 0.7em;
  padding: 0 25px;
  position: relative; /* Required for pseudo-element positioning */
  overflow: hidden; /* Ensures the glare effect is contained within the button */
  text-decoration: none;
  transition: all 250ms ease-in-out;
  z-index: 0;
}

.trybutton::after {
  content: "";
  position: absolute;
  top: -50%; /* Start outside the button */
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.8),
    transparent
  ); /* Increased opacity */
  transform: rotate(45deg);
  animation: glare 2.5s infinite linear;
}

@keyframes glare {
  0% {
    top: -50%;
    left: -50%;
  }
  100% {
    top: 100%;
    left: 100%;
  }
}

.trybutton-principal,
.try-button {
  background-color: white;
  border-style: none;
  color: #00777b;
  margin-left: 0;
}

.trybutton-principal * {
  pointer-events: none;
}

.trybutton:hover {
  transform: scale(1.05);
}

.trybuttons-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.trybuttons-wrapper div {
  flex: 1;
}

.first-try-button {
  font-size: 1em;
}

.first-try-button del {
  display: none;
}

@media screen and (min-width: 768px) {
  .first-try-button {
    font-size: initial;
  }

  .trybuttons-wrapper {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .first-try-button del {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .trybuttons-wrapper {
    display: none;
  }
}

.text-try {
  font-size: 1.2em;
}

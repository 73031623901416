.aboutContainer {
  width: 80%;
  margin: 10px 10%;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.aboutCardContainer {
  width: 100%;
  height: 520px;
  background-color: rgb(214, 214, 214);
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  padding: 0 25px;
  padding-top: 20px;
}

.aboutCardHeader {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: rgb(47, 47, 47);
}

.aboutCardSub {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  color: rgb(113, 113, 113);
}

.MuiSvgIcon-root.aboutCardIcon {
  font-size: 45px !important;
  margin: 12px 0;
}

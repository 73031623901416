svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.inherited-styles-for-exported-element {
  color: #333;
  font-family: "Poppins", sans-serif;
  font-size: 100%;
}

.button-header {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 50px;
  box-shadow: rgba(0, 12, 32, 0.04) 0 0 1px 0, rgba(10, 31, 68, 0.1) 0 3px 4px 0;
  color: #555;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin-top: 0.7em;
  padding: 0 25px;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  transition-behavior: normal;
  /* will-change: transform; */
}

.button-principal-header,
.buy-button-header {
  background-color: #00777b;
  border-style: none;
  color: white;
  margin-left: 0;
}

.button-principal-header * {
  pointer-events: none;
}

.button-header svg {
  margin-right: 0.5em;
}

.button-header:hover {
  transform: scale(1.05);
}

.buttons-wrapper-header {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.buttons-wrapper-header a {
  flex: 1;
}

.first-buy-button-header {
  font-size: 1em;
}

.first-buy-button-header del {
  display: none;
}
/* 
@media only screen and (max-width: 1050px) {
  .first-buy-button-header {
    font-size: 0.7em;
  }

  .button-header {
    padding: 0 5px;
    height: 25px;
    line-height: 40px;
  }
} */

@media only screen and (max-width: 600px) {
  .first-buy-button-header {
    font-size: 0.7em;
  }

  .button-header {
    padding: 0 5px;
    height: 25px;
    line-height: 30px;
    width: 120px;
  }

  .buttons-wrapper-header {
    width: 135px;
  }
}

@media screen and (min-width: 768px) {
  .first-buy-button-header {
    font-size: initial;
  }

  .buttons-wrapper-header {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .video-btn {
    display: none;
  }

  .big-price-header {
    font-size: 0.6em;
  }
}

@media screen and (min-width: 768px) {
  .first-buy-button-header del {
    display: initial;
  }
}

.big-price-header {
  font-size: 1.2em;
}

.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}

.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.whyus-section {
  width: 100%;
  padding: 10px 20px;
  background-color: #00777b;
}

.whyus-container {
  text-align: left;
  justify-content: left;
  vertical-align: middle;
  width: 60%;
  /* height: 150px; */
  margin: 10px 20%;
}

.whyus-text {
  font-size: 20px;
  color: white;
}

.whyus-title {
  font-size: 45px;
  font-weight: 700;
  color: white;
  margin: 10px 0px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

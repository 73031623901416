.modal__header {
  display: flex !important;
}

.modal__header button {
  margin-left: auto !important;
  font-size: 30px !important;
  font-family: "Poppins", sans-serif;
  border: none !important;
  background: #ffffff !important;
  cursor: pointer !important;
}

.modal__header img {
  margin-left: 10px !important;
  margin-top: 13px !important;
  float: right !important;
  font-size: 30px !important;
  font-family: "Poppins", sans-serif;
  border: none !important;
  background: #ffffff !important;
  cursor: pointer !important;
}

.modal__body {
  padding: 20px !important;
}

.modal__footer {
  display: flex !important;
  justify-content: flex-end !important;
}

.image__container {
  /* overflow: auto; */
  overflow: scroll !important;
}

.captureBtn {
  z-index: 100000000000000 !important;
  position: fixed !important;
  /* bottom: 10px;
    right: 15px; */
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  background-color: #5d50c7 !important;
  cursor: pointer !important;
}

.captureBtnHide {
  display: hidden !important;
}

/* .captured-img-modal-container {
  width: 1050px !important;
  min-height: 68vh !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border-radius: 8px !important;
  position: relative !important;
  overflow: auto !important;
  margin: 100px auto !important;
  z-index: 8000 !important;
  text-align: center !important;
} */

.captured-img-modal-container {
  width: 1000px !important;
  min-height: 60vh !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border-radius: 8px !important;
  position: relative !important;
  /* display: block; */
  overflow: auto !important;
  margin: 100px auto !important;
  z-index: 8000 !important;
  text-align: center !important;
}

.captured-img-modal {
  max-width: 70% !important;
  max-height: 70% !important;
  margin: auto !important;
}

.popup-overlay {
  overflow: auto !important;
}

.modal-container-bottom {
  width: 100% !important;
  margin: -20px 0px 20px 0px !important;
  padding: 0px 5% !important;
  display: flex !important;
}

.code-viewer-container {
  height: 300px !important;
  /* width: 45%; */
  margin: auto !important;
}

.image-modal-title-input {
  width: 100% !important;
  margin: 20px auto !important;
}

.image-modal-folder-select {
  width: 100% !important;
  margin: auto !important;
}

.add-new-folder-btn {
  color: #1375e0 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
  display: flex !important;
  cursor: pointer !important;
}

.add-new-folder-btn:hover {
  color: #0a4e96 !important;
}

.saveMessageError {
  text-align: center !important;
  margin-top: 10% !important;
}

.saveSubMessageError {
  text-align: center !important;
  margin-top: 20px !important;
}

.ImagePopupBtnsContainer {
  width: 100% !important;
  justify-content: center !important;
  text-align: center !important;
}

.loadingPosition {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: "translate(-50%, -50%)" !important;
}

.imageLogo {
}

.hover-highlight {
  outline: 2px solid blue !important; /* Change the color as needed */
}

.highlight-selected {
  outline: 2px solid red !important; /* Change the color as needed */
}

.copy-btn {
  height: 25px !important;
  width: 70px !important;
  background-color: black !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 12px;
  border: 1px solid black !important;
  border-radius: 20px !important;
  cursor: pointer !important;
  font-family: "Poppins", sans-serif;
}

/**************** LISENCE POPUP *****************/

.App {
  text-align: center !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.image-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: auto !important;
  margin-bottom: 15px !important;
  max-height: 300px !important;
  padding: 10px !important;
  border: 5px solid var(--purple) !important;
  border-radius: 5px !important;
  max-width: 80% !important;
  margin: auto !important;
}

#selected-image {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
}

.crosshairs {
  position: absolute !important;
  width: 100% !important;
  z-index: 2147483645 !important;
}

.crosshairs.hidden {
  display: none !important;
}

.crosshairs::before,
.crosshairs::after {
  content: "" !important;
  position: absolute !important;
}

.crosshairs::before {
  height: 24px !important;
  width: 2px !important;
  background: #000 !important;
  top: -11px !important;
}

.crosshairs::after {
  width: 24px !important;
  height: 2px !important;
  background: #000 !important;
  left: -11px !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 100000000000000 !important;
}

.overlay.highlighting {
  background: none !important;
  border-color: rgba(0, 0, 0, 0.5) !important;
  border-style: solid !important;
}

/******** CONTROLS BUTTONS ******/
.controls-container {
  width: 110px !important;
  height: 50px !important;
  border-radius: 100px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  position: fixed !important;
  /* top: 20px;
  right: 20px; */
  display: flex !important;
  justify-content: space-between !important;
  padding: 5px !important;
  z-index: 1000000 !important;
  background-color: white !important;
}

.controls-container-none {
  display: none !important;
}

/* .copy-btn {
  height: 25px !important;
  width: 60px !important;
  background-color: black !important;
  color: white !important;
  font-weight: 700 !important;
  border: 1px solid black !important;
  border-radius: 20px !important;
  cursor: pointer !important;
} */

.code-container-box {
  /* overflow-y: scroll; */
  overflow-y: scroll;
  overflow-x: scroll;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  padding-top: 5px;
  width: 100%;
  height: 280px;
  background-color: #1995b1;
  border-radius: 8px;

  text-align: left;
  white-space: pre-wrap;
  word-break: break-all;
}

.code-container-box::-webkit-scrollbar-thumb {
  background-color: black;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.code-container-box::-webkit-scrollbar {
  width: 16px;
}

.code-container-header {
  width: 100% !important;
  height: 50px !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 8px !important;
  align-items: baseline !important;
  padding: 0 10px !important;
}

.code-container-header h3 {
  float: left;
  font-size: 20px;
  color: black;
  font-family: "Poppins", sans-serif;
}

.code-container {
  width: 45% !important;
  margin: 0 auto !important;
}

.MuiSvgIcon-root.controls-arrow-up {
  transform: rotate(0deg) !important;
  font-size: 22px !important;
  color: black;
  font-family: "Poppins", sans-serif;
  /* margin: 5px 5px; */
}

.MuiSvgIcon-root.controls-arrow-down {
  transform: rotate(180deg) !important;
  font-size: 22px !important;
  color: black;
  /* margin: 5px 5px; */
}

.controls-buttons-container:focus {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

/* .controls-buttons-container {
  padding: 8px 8px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  border-radius: 50% !important;
} */

.controls-container div {
  /* margin: 0 auto; */
  padding: 8px 8px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  border-radius: 50% !important;
}

/******** **************** ******/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Poppins", sans-serif;
}

.App-link {
  color: #61dafb;
}

/***************** TRY BUTTON  ******************/

/***************** TRY BUTTON  ******************/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#time-up {
  display: none;
}
.counter-counter-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a1a40;
  color: black;
}

.counter-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.counter-timer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: fl; */
}
.counter-timer {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.counter-container span {
  color: #c7c7c7;
  font-size: 18px;
}

.topSectionContainer {
  width: 80%;
  margin: 0 10%;
  margin-top: 140px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
}

.topSectionLeftPart {
  width: 50%;
  margin: 4px 2.5px;
}

.topSectionRightPart {
  width: 40%;
  margin: 20px 2.5px;
  margin-top: 0;
}

.topSectionTitle {
  font-size: 45px;
  font-weight: 700;
  color: rgb(30, 30, 30);
  margin: 10px 0px;
  font-family: "Poppins", sans-serif;
}

.topSectionSubTitle {
  font-size: 20px;
  font-weight: 400;
  color: rgb(30, 30, 30);
  margin: 5px 0px;
}

.videoStyling {
  width: 100%;
  margin: 0 auto;
  /* height: 300px; */
  /* margin-right: 200px; */
}

@media only screen and (max-width: 1050px) {
  .topSectionContainer {
    display: inline;
  }

  .topSectionLeftPart {
    width: 80%;
    margin: 40px 10%;
  }

  .topSectionRightPart {
    width: 80%;
    margin: 4px 10%;
  }
}

/* ".inherited-styles-for-exported-element" is a generated class for the inherited styles of the exported element, feel free to rename it. */

#clock {
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
  width: 80%;
}

.timerContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2em;
}

#clock .label {
  font-size: 0.7em;
}

#clock .colon {
  font-size: 1.5em;
  margin-top: -24px;
}

.lifetime-offer-banner {
  color: white;
  background-color: #00777b;
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  position: relative; /* Required for pseudo-element positioning */
  overflow: hidden; /* Ensures the glare effect is contained within the button */
  text-decoration: none;
  transition: all 250ms ease-in-out;
  z-index: 0;
  border-radius: 5px;
  padding: 10px 0;
}

.lifetime-offer-banner::after {
  content: "";
  position: absolute;
  top: -50%; /* Start outside the button */
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.8),
    transparent
  ); /* Increased opacity */
  transform: rotate(45deg);
  animation: glare 2.5s infinite linear; /* Faster animation */
}

@keyframes glare {
  0% {
    top: -50%;
    left: -50%;
  }
  100% {
    top: 100%;
    left: 100%;
  }
}

@media screen and (min-width: 768px) {
  #clock {
    width: 55%;
  }
}

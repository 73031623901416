.guideCardContainer {
  width: 100%;
  min-height: 300px;
  float: left;
}

.guideNumbering {
  color: #00777b;
  font-weight: 700;
  font-size: 40px;
  float: left;
  width: 100%;
}

.guideImg {
  width: 70%;
  height: auto;
  border: 20px white solid;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.guideDesc {
  color: rgb(30, 30, 30);
  font-weight: 400;
  font-size: 16px;
  float: left;
  width: 65%;
  margin-top: 20px;
  text-justify: auto;
  display: inline;
  font-family: "Poppins", sans-serif;
}

.header {
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 100;
}

.headerLogo {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  /* margin: 20px 0px; */
}

.headerCta {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 420px !important;
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1050px) {
  .headerLogo {
    height: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .headerLogo {
    height: 35px;
  }

  .headerCta {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 150px !important;
  }
}

.paymentfinfoform {
  width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.paymentfinfoform label {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.paymentfinfoform input[type="text"],
.paymentfinfoform input[type="email"] {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
}

.infoformbtn-container {
  text-align: center;
}

.paymentfinfoform .submit-button {
  background-color: #00777b;
  border: 1px solid #eee;
  border-radius: 50px;
  box-shadow: rgba(0, 12, 32, 0.04) 0 0 1px 0, rgba(10, 31, 68, 0.1) 0 3px 4px 0;
  color: white;
  display: inline-block;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  margin-top: 0.7em;
  padding: 0 25px;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  transition-behavior: normal;
  width: 50%;
  font-size: 16.5px;
}

.paymentfinfoform .submit-button:hover {
  background-color: white;
  color: #555;
}

.paymentfinfoform .payment-resp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 40%;
  height: 40vh;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.paymentfinfoform .payment-resp p {
  margin: 0;
}

.form-close-btn {
  background-color: #00777b;
  border-style: none;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

/* .infoform__header img {
  margin-left: 10px !important;
  margin-top: 13px !important;
  float: right !important;
  font-size: 30px !important;
  font-family: "Poppins", sans-serif;
  border: none !important;
  background: #ffffff !important;
  cursor: pointer !important;
} */

.infoform__header {
  /* display: flex !important; */
  height: 10px;
}

.infoform-exit {
  margin-left: auto !important;
  font-size: 30px !important;
  font-family: "Poppins", sans-serif;
  border: none !important;
  background: #ffffff !important;
  cursor: pointer !important;
  color: black;
  border: none;
  outline: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

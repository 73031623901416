.pricing-section {
  text-align: center;
  align-items: center;
  margin: 50px 0;
}

.pricing-box-box {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pricing-box {
  padding: 10px;
  margin: auto;
}

.pricing-box h2 {
  font-size: 24px;
  /* margin-bottom: 10px; */
  color: #333333;
}

.pricing-box .main-price {
  font-size: 35px;
  color: #999999;
  text-decoration: line-through;
  /* margin-bottom: 1px; */
}

.pricing-box .discounted-price {
  font-size: 55px;
  color: #00777b;
  margin-bottom: -40px;
  margin-top: 0px;
}

.pricing-box .benefits {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.pricing-box .benefits li {
  font-size: 16px;
  color: #555555;
  margin-bottom: 10px;
}

.pricing-box .btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  color: #ffffff;
  background-color: #00777b;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pricing-box .btn:hover {
  background-color: #005f62;
}

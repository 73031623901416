/* .footer-section {
  background-color: rgb(30, 30, 30);
  height: 80px;
  width: 100%;
  font-size: 30px;
  font-weight: 400;
} */

/* src/components/footer.css */
/* src/components/footer.css */
.footer-section {
  background-color: rgb(30, 30, 30);
  padding: 10px 20px; /* Adjust padding for better spacing */
  width: 100%;
  font-size: 16px; /* Reduce font size for the links */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items to the start */
  color: white;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin-bottom: 5px; /* Reduce margin between links */
}

.footer-links a:hover {
  text-decoration: underline;
}
